import React, { useReducer, Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  TableWrapper as RawTableWrapper,
  ButtonReset,
  Stack,
} from '@tymate/margaret';
import { orderBy, find, last, first } from 'lodash';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import LocationAwareSearch from './New/LocationAwareSearch';
import { useDeepCompareEffect } from 'react-use';
import { useSearchParams } from '../hooks';
import { overrideSearchWithParams } from '../utils';
import { useLocation } from 'react-use';
import { TableFooterActions } from '../ui/table';
import Loading from '@tymate/elise/components/Loading';
import { EmptyState } from '../ui';
import Tooltip from './Tooltip';
import IcChevronUpDown from '../icons/IcChevronUpDown';

const InheritButtonReset = styled(ButtonReset)`
  text-align: inherit;
`;

const ASC = 'asc';
const DESC = 'desc';

const generateColumns = css`
  grid-template-columns: ${({ columnsWidths = [] }) => columnsWidths.join(' ')};
`;

const Table = styled.div`
  display: grid;
  ${generateColumns};
`;

const Th = styled(Stack).attrs({ alignY: 'center' })`
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  white-space: nowrap;

  ${({ headerAlignY }) =>
    headerAlignY &&
    css`
      align-items: ${headerAlignY};
    `};

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      border-right: 1px solid ${({ theme }) => theme.separator};
    `};
`;

Th.defaultProps = {
  paddingHorizontal: 0.75,
  paddingVertical: 0.5,
};

const TableWrapper = styled(RawTableWrapper)`
  width: 100%;
  overflow-x: auto;
  overflow: visible;

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      overflow: visible;
      font-size: 14px;
      border-left: 1px solid ${({ theme }) => theme.separator};
      border-radius: 0;
      border-bottom: 0;
      margin-bottom: -1px;

      ${Th},
      ${Td} {
        padding: ${({ theme }) => theme.spacing(0.25)}
          ${({ theme }) => theme.spacing(0.5)};
      }
    `}

  ${({ variant }) =>
    variant === 'planning' &&
    css`
      background-color: ${({ theme }) => theme.grayLighter};
      overflow-x: auto;
      overflow: visible;
      border-radius: 0;
      border: 1px solid ${({ theme }) => theme.separator};
      border-right: none;

      ${Th},
      ${Td} {
        padding: ${({ theme }) => theme.spacing(0.5)};
        border-right: 1px solid ${({ theme }) => theme.separator};
      }

      ${Th} {
        background-color: #ebebeb;
      }
    `}

  ${({ shouldScrollHorizontally }) =>
    !shouldScrollHorizontally &&
    css`
      overflow-x: visible;
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: 100%;
    `}
`;

const Td = styled(Stack).attrs({ alignY: 'center' })`
  border-bottom: 1px solid ${({ theme }) => theme.separator};

  ${({ variant, children }) =>
    variant === 'planning' &&
    children?.props?.backgroundColor &&
    css`
      background-color: ${children?.props?.backgroundColor};
    `}

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      border-right: 1px solid ${({ theme }) => theme.separator};
    `};
`;

Td.defaultProps = {
  paddingHorizontal: 0.75,
  paddingVertical: 0.5,
};

const ColumnHeader = styled(ButtonReset)`
  font-weight: 600;
  svg {
    color: ${({ theme }) => theme.textLighter};
    transition: transform 150ms ease, color 150ms ease;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        color: ${({ theme }) => theme.text};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${({ svgShouldGetUpsideDown }) =>
    svgShouldGetUpsideDown &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Tr = styled.div`
  display: contents;
  position: relative;

  svg {
    font-size: 20px;
  }

  ${({ variant }) =>
    variant !== 'planning' &&
    css`
      &:hover ${Td} {
        background-color: ${({ theme }) => theme.orangeLightenBackground};
      }
    `}
`;

const TrLink = styled(Link)`
  display: contents;
  color: inherit;
  text-decoration: none;
`;

const TrContent = styled(Stack)`
  display: contents;
  position: relative;

  &:last-child ${Td} {
    border-bottom: none;
  }
`;

const TopActions = styled(Stack)`
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Content = ({ render, value }) =>
  render ? render() : <span>{value}</span>;

const getInitialState = headings =>
  headings
    .map(({ defaultSort, defaultIsHidden, defaultIsActive, ...heading }) => ({
      isActive: Boolean(defaultIsActive),
      isHidden: Boolean(defaultIsHidden),
      sort: defaultSort,
      ...heading,
    }))
    // 👇👇👇
    // If  multiple headings have defaultIsActive: true, only the first one
    // is kept. The “isActive” property of the following ones then is then set
    // to false.
    .reduce((acc, curr) => {
      if (find(acc, ({ isActive }) => isActive)) {
        return acc.concat({
          ...curr,
          isActive: false,
        });
      }

      return acc.concat(curr);
    }, []);

const getNextSort = currentSort => {
  switch (currentSort) {
    case ASC:
      return DESC;
    case DESC:
      return null;
    default:
      return ASC;
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_SORT':
      return state.map(heading => {
        if (heading.slug !== payload) {
          return {
            ...heading,
            isActive: false,
          };
        }

        if (!heading.isActive) {
          return {
            ...heading,
            isActive: true,
            sort: Boolean(heading.sort) ? heading.sort : getNextSort(),
          };
        }

        return {
          ...heading,
          isActive: Boolean(getNextSort(heading.sort)),
          sort: getNextSort(heading.sort),
        };
      });

    case 'TOGGLE_VISIBILITY':
      return state.map(heading =>
        heading.slug === payload
          ? { ...heading, isHidden: !heading.isHidden }
          : heading,
      );

    case 'INITIALIZE':
      return payload;

    default:
      return state;
  }
};

const getNextSearchParam = column => {
  const nextSort = getNextSort(column?.sort);

  if (!nextSort) {
    return null;
  }

  return column.slug
    .split(',')
    .map(slug => (nextSort === DESC ? `-${slug}` : slug))
    .join(',');
};

const getSearchParamsActiveHeading = ({ headings, sort }) => {
  const activeHeading = find(
    headings,
    ({ slug }) => slug === (sort || '').replace(/-/g, ''),
  );

  if (!activeHeading || !sort) {
    return null;
  }

  return {
    ...activeHeading,
    isActive: true,
    sort: sort.charAt(0) === '-' ? DESC : ASC,
  };
};

const DataTable = ({
  onSort,
  action,
  tableBottom,
  sortingIsHandledBySearchQuery = true,
  isSearchable,
  shouldHideTableHead,
  footerActions,
  loading,
  searchParamName,
  footer,
  shouldScrollHorizontally = true,
  maxWidth = false,
  accordion,
  headerAlignY,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const [{ sort }] = useSearchParams();
  const [rawHeadings, dispatch] = useReducer(
    reducer,
    getInitialState(props.headings),
  );
  const activeHeading = sortingIsHandledBySearchQuery
    ? getSearchParamsActiveHeading({ headings: rawHeadings, sort })
    : find(rawHeadings, ({ isActive }) => isActive);
  const headings = rawHeadings
    .filter(({ isHidden }) => !isHidden)
    .map(heading =>
      heading?.slug === activeHeading?.slug ? activeHeading : heading,
    );

  const data = Boolean(activeHeading)
    ? orderBy(props.data, activeHeading.slug, activeHeading.sort)
    : props.data;

  const columnsWidths = headings.map(column => {
    if (!column) {
      return '';
    }

    if (Boolean(column?.size)) {
      return column.size;
    }

    if (column?.slug === 'actions') {
      return 'min-content';
    }

    return 'auto';
  });

  const linkColumnSpan = headings.filter(
    ({ slug }) => slug !== 'actions' && slug !== 'select',
  )?.length;

  const handleReorderColumn = columnSlug => {
    if (sortingIsHandledBySearchQuery) {
      const search =
        overrideSearchWithParams({
          location,
          sort: getNextSearchParam(
            find(headings, ({ slug }) => slug === columnSlug),
          ),
        }) || '';

      history.push({
        pathname: location.pathname,
        search,
      });
    } else {
      dispatch({ type: 'TOGGLE_SORT', payload: columnSlug });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: getInitialState(props.headings),
    });
  }, [props.headings]);

  useDeepCompareEffect(() => {
    if (typeof onSort === 'function' && Boolean(activeHeading)) {
      onSort(activeHeading);
    }
  }, [{ activeHeading }]);

  return (
    <>
      {props.variant !== 'nested' && (
        <TopActions alignX="space-between" alignY="center">
          {isSearchable && (
            <LocationAwareSearch searchParamName={searchParamName} />
          )}
          {action}
        </TopActions>
      )}

      <TableWrapper
        variant={props.variant || 'bordered'}
        shouldScrollHorizontally={shouldScrollHorizontally}
        maxWidth={maxWidth}
      >
        <Table columnsWidths={columnsWidths}>
          {!shouldHideTableHead && (
            <Tr columnsWidths={columnsWidths}>
              {headings
                .filter(Boolean)
                .filter(({ isHidden }) => !isHidden)
                .map(
                  ({
                    slug,
                    label,
                    cannotBeReordered,
                    sort,
                    isActive,
                    variant,
                    fixed,
                    render,
                    toolTipText,
                    hasSeparator,
                    ...props
                  }) => (
                    <Th
                      key={slug}
                      variant={variant}
                      headerAlignY={headerAlignY}
                      hasSeparator={hasSeparator}
                      {...props}
                    >
                      {Boolean(label) && !render && toolTipText && (
                        <ColumnHeader
                          onClick={() => handleReorderColumn(slug)}
                          disabled={cannotBeReordered}
                          isActive={isActive}
                          svgShouldGetUpsideDown={sort === ASC}
                        >
                          <Stack alignX="center" alignY="center">
                            <Tooltip
                              variant="orange"
                              tip={<span>{toolTipText}</span>}
                            >
                              <span>{label}</span>
                            </Tooltip>
                            {cannotBeReordered ? null : isActive &&
                              Boolean(sort) ? (
                              <MdKeyboardArrowDown
                                style={{ alignSelf: 'center' }}
                              />
                            ) : (
                              <IcChevronUpDown
                                style={{ alignSelf: 'center' }}
                                size={16}
                              />
                            )}
                          </Stack>
                        </ColumnHeader>
                      )}

                      {Boolean(label) && !render && !toolTipText && (
                        <ColumnHeader
                          onClick={() => handleReorderColumn(slug)}
                          disabled={cannotBeReordered}
                          isActive={isActive}
                          svgShouldGetUpsideDown={sort === ASC}
                        >
                          <Stack alignY="center">
                            <span>{label}</span>
                            {cannotBeReordered ? null : isActive &&
                              Boolean(sort) ? (
                              <MdKeyboardArrowDown />
                            ) : (
                              <IcChevronUpDown size={16} color="red" />
                            )}
                          </Stack>
                        </ColumnHeader>
                      )}

                      {render && <ColumnHeader>{render()}</ColumnHeader>}
                    </Th>
                  ),
                )}
            </Tr>
          )}

          {data.map((data, index) => {
            const key = data?.id?.value || data?.id || index;

            return (
              <Fragment key={key}>
                <TrContent>
                  <Tr variant={props?.variant} hasLink={data.path || data.href}>
                    {data.path || data.href || data.onClick ? (
                      linkColumnSpan === columnsWidths.length ? (
                        <TrLink
                          to={data.path}
                          href={data.href}
                          target={data.href ? '_blank' : null}
                          as={
                            Boolean(data.href)
                              ? 'a'
                              : Boolean(data.as)
                              ? data.as
                              : Boolean(data.onClick)
                              ? ButtonReset
                              : null
                          }
                          onClick={data.onClick}
                        >
                          {headings
                            .filter(({ isHidden }) => !isHidden)
                            .map(({ slug, variant, ...props }) => (
                              <Td key={slug} variant={variant} {...props}>
                                <Content key={index} {...data[slug]} />
                              </Td>
                            ))}
                        </TrLink>
                      ) : (
                        <>
                          {first(headings)?.slug === 'select' && (
                            <Td
                              key={first(headings)?.slug}
                              variant={first(headings)?.variant}
                              alignY="center"
                              {...props}
                              {...first(headings)}
                            >
                              <Content
                                key={index}
                                {...data[first(headings)?.slug]}
                              />
                            </Td>
                          )}
                          <TrLink
                            to={data.path}
                            href={data.href}
                            target={data.href ? '_blank' : null}
                            as={
                              Boolean(data.href)
                                ? 'a'
                                : Boolean(data.as)
                                ? data.as
                                : Boolean(data.onClick)
                                ? InheritButtonReset
                                : null
                            }
                            onClick={data.onClick}
                          >
                            {headings
                              .filter(Boolean)
                              .filter(
                                ({ slug }) =>
                                  slug !== 'select' && slug !== 'actions',
                              )
                              .slice(
                                0,
                                first(headings)?.slug === 'select' &&
                                  last(headings)?.slug !== 'actions'
                                  ? linkColumnSpan - 1
                                  : linkColumnSpan,
                              )
                              .filter(({ isHidden }) => !isHidden)
                              .map(({ slug, variant, ...props }) => (
                                <Td key={slug} variant={variant} {...props}>
                                  <Content key={index} {...data[slug]} />
                                </Td>
                              ))}
                          </TrLink>
                          <Td
                            key={last(headings)?.slug}
                            variant={last(headings)?.variant}
                            alignY="center"
                            {...props}
                            {...last(headings)}
                          >
                            <Content
                              key={index}
                              {...data[last(headings)?.slug]}
                            />
                          </Td>
                        </>
                      )
                    ) : (
                      headings
                        .filter(Boolean)
                        .filter(({ isHidden }) => !isHidden)
                        .map(({ slug, variant, ...props }) => (
                          <Td key={slug} variant={variant} {...props}>
                            <Content key={index} {...data[slug]} />
                          </Td> //
                        ))
                    )}
                  </Tr>
                  {data.extraInfo}
                </TrContent>
                {accordion?.indexRow === index ? (
                  <Stack size="full" style={{ gridColumn: '1 / -1' }}>
                    {accordion?.component}
                  </Stack>
                ) : null}
              </Fragment>
            );
          })}
          {Boolean(footer) && (
            <>
              {headings.filter(Boolean).map(({ slug }, index) => (
                <Th key={index} style={{ whiteSpace: 'nowrap' }}>
                  <Content key={slug} {...footer[slug]} />
                </Th>
              ))}
            </>
          )}
        </Table>

        {!loading && footerActions && (
          <TableFooterActions followsEmptyTable={data.length === 0}>
            {footerActions}
          </TableFooterActions>
        )}

        {loading && <Loading size={40} />}
        {!loading && data?.length === 0 && (
          <EmptyState>Pas de données</EmptyState>
        )}
      </TableWrapper>
    </>
  );
};

export default DataTable;
