import React from 'react';
import styled from '@react-pdf/styled-components';
import { View, Text } from '@react-pdf/renderer';
import { sum } from 'lodash';
import { createMonthlyDatesOrdered } from '@tymate/elise/utils';
import { formatNumberToPrettyString } from '@tymate/elise/utils';
import { formatDate } from '@tymate/elise/utils';

const Title = styled(Text)`
  font-size: 16px;
  margin: 0 auto;
  padding-bottom: 16px;
`;

const Table = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 9px;
  border: 1px solid #1e2020;
  border-radius: 8px;
`;

const Row = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ${({ odd }) =>
    odd &&
    `
    background-color: #E0E0E0;
  `};

  ${({ lastRow }) =>
    lastRow &&
    `
    border-top: 1px solid #767676;
  `};
`;

const Cell = styled(View)`
  flex: 1;
  text-align: center;
  padding: 6px 0 4px 0;

  ${({ first }) =>
    first &&
    `
    flex: 4;
  `}

  ${({ last }) =>
    last &&
    `
    flex: 2;
  `}

  ${({ month }) =>
    month &&
    `
    color: #fff;
    background-color: #767676;
    text-transform: capitalize;
  `};

  ${({ color }) =>
    color &&
    `
    color: #fff;
    background-color: ${color};
  `};

  ${({ textAlignLeft }) =>
    textAlignLeft &&
    `
    text-align: left;
  `}
`;

const Label = styled(Text)`
  padding-left: 4px;
`;

const TOTAL_NB_OF_MONTH = 12;

const DataTable = ({ data, startAt, endAt }) => {
  const monthlyDatesOrdered = createMonthlyDatesOrdered(
    TOTAL_NB_OF_MONTH,
    startAt,
    endAt,
  );

  const wastes = Object.values(data);
  const wasteType = wastes.map(waste => ({
    text:
      waste[0].label.charAt(0).toUpperCase() +
      waste[0].label.slice(1).toLowerCase(),
    color: waste[0].color || '#eb732c',
  }));
  const nbOfWasteType = wasteType.length;

  const gridValues = Array.from(Array(nbOfWasteType), () =>
    Array(TOTAL_NB_OF_MONTH).fill(0),
  );

  wastes.forEach((waste, rowIndex) => {
    waste.forEach(({ month, value }) => {
      const colIndex = monthlyDatesOrdered.findIndex(
        ({ value }) => formatDate(value, 'MM/yyyy') === month,
      );
      if (colIndex > -1) {
        gridValues[rowIndex][colIndex] = gridValues[rowIndex][colIndex] + value;
      }
    });
  });

  const gridValuesNoZero = [];
  const wasteTypeNoZero = [];
  gridValues.forEach((waste, index) => {
    gridValuesNoZero.push(waste);
    wasteTypeNoZero.push(wasteType[index]);
  });

  const totalsCol = Array.from(Array(nbOfWasteType), (_, index) =>
    sum(gridValuesNoZero[index]),
  );
  const totalsRow = Array.from(Array(TOTAL_NB_OF_MONTH), (_, index) =>
    sum(gridValuesNoZero.map(values => values[index])),
  );
  const finalTotal = sum(totalsCol);
  return (
    <>
      <Title>Suivi des quantités de déchets collectés (kg)</Title>
      <Table>
        <Row>
          <Cell first>
            <Text>Gisement collectés sur la période</Text>
          </Cell>
          {monthlyDatesOrdered.map(({ label }) => (
            <Cell month>
              <Text>{label}</Text>
            </Cell>
          ))}
          <Cell last>
            <Text>TOTAL</Text>
          </Cell>
        </Row>
        {gridValuesNoZero.map((values, index) => {
          return (
            <Row key={index} odd={index % 2 !== 0}>
              <Cell first color={wasteTypeNoZero[index]?.color} textAlignLeft>
                <Label>{wasteTypeNoZero[index]?.text}</Label>
              </Cell>
              {values.map(value => (
                <Cell>
                  <Text>{`${formatNumberToPrettyString(value)}`}</Text>
                </Cell>
              ))}
              <Cell last color={wasteTypeNoZero[index]?.color}>
                <Text>{`${formatNumberToPrettyString(totalsCol[index])}`}</Text>
              </Cell>
            </Row>
          );
        })}
        <Row lastRow>
          <Cell first>
            <Text>TOTAL</Text>
          </Cell>
          {totalsRow.map(total => (
            <Cell>
              <Text>{`${formatNumberToPrettyString(total)}`}</Text>
            </Cell>
          ))}
          <Cell last>
            <Text>{`${formatNumberToPrettyString(finalTotal)}`}</Text>
          </Cell>
        </Row>
      </Table>
    </>
  );
};

export default DataTable;
