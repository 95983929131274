import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@tymate/elise';
import { Container, EmptyState, theme, Title } from '@tymate/elise/ui';
import { DatePicker, Modal } from '@tymate/elise/components';
import CollectionPointsTree from 'components/CollectionPointsTree';
import PdfPage from 'components/PdfPage';
import { FormLabel as RawFormLabel } from '@tymate/elise/ui/forms';
import { isEmpty, debounce } from 'lodash';
import { CollectionPointContext } from 'contexts';
import {
  getCollectedResourcesCSV,
  getReportings,
  getWasteRegisterCSV,
} from 'api/reporting';
import ReportingGraph from 'components/ReportingGraph';
import {
  ReportingHeader,
  ReportingCard,
  ReportingContent,
  Values,
  TextValue,
  Circle,
  Label,
  Equivalences,
  EquivalencesTitle,
  Equivalence,
  EquivalencesList,
  ReportingItem,
} from 'ui';
import { useQuery } from 'react-query';
import { RiTableFill } from 'react-icons/ri';
import { MdFileDownload } from 'react-icons/md';
import { saveAs } from 'file-saver';
import { Stack, Spinner } from '@tymate/margaret';
import { addYears, startOfDay, addMonths, endOfDay, isValid } from 'date-fns';
import { useDeepCompareEffect } from 'react-use';
import { useAppState } from 'hooks';
import { formatNumberToPrettyString, formatDate } from '@tymate/elise/utils';

import icCo2 from 'images/equivalence-co2.svg';
import icEnergy from 'images/equivalence-energy.svg';
import icOil from 'images/equivalence-oil.svg';
import icWater from 'images/equivalence-water.svg';
import icWork from 'images/equivalence-work.svg';

import IcTrain from 'images/icons/IcTrain';
import IcCar from 'images/icons/IcCar';
import IcComputer from 'images/icons/IcComputer';
import IcPeople from 'images/icons/IcPeople';
import IcShower from 'images/icons/IcShower';

const FormLabel = styled(RawFormLabel)`
  width: auto;
  margin-bottom: 0;
`;

const StateTag = styled.span`
  width: max-content;
  background-color: ${({ theme }) => theme.primaryLighten};
  font-size: 12px;
  color: ${({ theme }) => theme.primary};
  border-radius: 8px;
  padding-right: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  line-height: 24px;
  height: 24px;
`;

const filterReportings = reportings =>
  (reportings || []).filter(({ label }) => label);

const filterReportingsDataTable = reportings => {
  const filteredReportings = {};

  for (const key in reportings) {
    const labels = reportings[key].map(({ label }) => label).filter(Boolean);

    if (labels.length > 0) {
      filteredReportings[key] = reportings[key];
    }
  }
  return filteredReportings;
};

const HomeReporting = () => {
  const { notify } = useAppState();
  const { collectionPoint } = useContext(CollectionPointContext);
  const initialParams = {
    collectionPointIds: collectionPoint?.id ? [collectionPoint?.id] : [],
    startAt: startOfDay(addYears(new Date(), -1)),
    endAt: startOfDay(new Date()),
  };
  const [
    collectionPointsSelectModalIsShown,
    setCollectionPointsSelectModalIsShown,
  ] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [canvas, setCanvas] = useState();
  const [canvasDataUrl, setCanvasDataUrl] = useState();
  const [startDateIsUpdated, setStartDateIsUpdated] = useState(false);

  const paramsAreFilled =
    Boolean(params?.startAt) &&
    Boolean(params?.endAt) &&
    (params?.collectionPointIds || []).length > 0;

  const { data, isLoading, isSuccess } = useQuery(
    ['reporting', params],
    () =>
      getReportings({
        params: { ...params, endAt: endOfDay(params.endAt) },
      }),
    {
      enabled: paramsAreFilled,
    },
  );

  const handleDownloadCollectedRessourcesCsv = async () => {
    try {
      const { data } = await getCollectedResourcesCSV({ params });
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, 'reporting.csv');
    } catch (e) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleDownloadWasteRegisterCsv = async () => {
    try {
      const { data } = await getWasteRegisterCSV({
        params,
      });
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, 'suivi des collectes.csv');
    } catch (e) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const reportings = filterReportings(data?.data?.weighingGraph);
  const reportingsDataTable = filterReportingsDataTable(
    data?.data?.wasteWeights,
  );

  const equivalences = data?.data?.equivalences ?? [];
  const handleSelectCollectionPoints = collectionPointIds => {
    setParams({ ...params, collectionPointIds });
    setCollectionPointsSelectModalIsShown(false);
  };

  const debouncedStartDateChange = debounce(date => {
    if (isValid(date)) {
      setParams({ ...params, startAt: startOfDay(date) });
      setStartDateIsUpdated(true);
    } else {
      throw new Error('Invalid date on start date change');
    }
  }, 800);

  const handleStartDateChange = date => {
    debouncedStartDateChange(date);
  };

  const debouncedEndDateChange = debounce(date => {
    if (isValid(date)) {
      setParams({ ...params, endAt: date });
    } else {
      throw new Error('Invalid date on end date change');
    }
  }, 800);

  const handleEndDateChange = date => {
    debouncedEndDateChange(date);
  };

  useDeepCompareEffect(() => {
    setParams(initialParams);
  }, [{ initialParams }]);

  useDeepCompareEffect(() => {
    setParams({ ...params, endAt: addYears(params.startAt, +1) });
    setTimeout(() => {
      setStartDateIsUpdated(false);
    }, 200);
    return () => clearTimeout();
  }, [params.startAt]);

  useDeepCompareEffect(() => {
    setTimeout(() => {
      setCanvas(document.querySelector('canvas'));
      setCanvasDataUrl(canvas?.toDataURL());
    }, 200);
    return () => clearTimeout();
  }, [{ reportings, canvas }]);

  return (
    <Container variant="main">
      <Stack
        size="full"
        alignX="space-between"
        alignY="flex-start"
        gutterSize={1}
        direction={{ default: 'column', medium: 'row' }}
        marginBottom={1}
      >
        <Stack direction="column" gutterSize={2}>
          <Stack alignY="center" gutterSize={1}>
            <Stack
              direction={{ default: 'column', tablet: 'row' }}
              alignY="center"
              gutterSize={0.5}
            >
              <FormLabel>Du</FormLabel>
              <DatePicker
                value={params.startAt}
                onChange={handleStartDateChange}
              />
            </Stack>

            <Stack
              direction={{ default: 'column', tablet: 'row' }}
              alignY="center"
              gutterSize={0.5}
            >
              <FormLabel>au</FormLabel>
              <DatePicker
                value={params.endAt}
                maxDate={!startDateIsUpdated && addYears(params.startAt, 1)}
                minDate={!startDateIsUpdated && addMonths(params.startAt, 1)}
                onChange={handleEndDateChange}
              />
            </Stack>
          </Stack>

          <Stack
            gutterSize={1}
            alignY="center"
            direction={{ default: 'column', tablet: 'row' }}
          >
            <Button
              variant="primary"
              onClick={() => setCollectionPointsSelectModalIsShown(true)}
            >
              Sélectionner les points de collecte à afficher
            </Button>
            {params.collectionPointIds.length > 0 && (
              <StateTag>
                {params.collectionPointIds.length}{' '}
                {params.collectionPointIds.length > 1
                  ? 'points de collecte sélectionnés'
                  : 'point de collecte sélectionné'}
              </StateTag>
            )}
          </Stack>
        </Stack>

        <Stack gutterSize={1} alignY="center">
          <Button
            variant="outline"
            type="button"
            disabled={!paramsAreFilled}
            onClick={handleDownloadWasteRegisterCsv}
            icon={<RiTableFill />}
          >
            Suivi des collectes
          </Button>
          <Button
            variant="outline"
            type="button"
            disabled={!paramsAreFilled}
            onClick={handleDownloadCollectedRessourcesCsv}
            icon={<RiTableFill />}
          >
            Reporting
          </Button>
          <PDFDownloadLink
            style={{ textDecoration: 'none' }}
            document={
              Boolean(canvasDataUrl) && (
                <PdfPage
                  reportings={reportings}
                  equivalences={equivalences}
                  reportingsDataTable={reportingsDataTable}
                  canvasUrl={canvasDataUrl}
                  params={{ ...params, collectionPoint }}
                />
              )
            }
            fileName="Reporting-document.pdf"
          >
            {({ loading }) => {
              return (
                <Button
                  variant="outline"
                  as="span"
                  icon={<MdFileDownload />}
                  disabled={
                    !(
                      paramsAreFilled &&
                      Boolean(canvasDataUrl) &&
                      !isEmpty(reportings) &&
                      !isEmpty(reportingsDataTable)
                    ) || loading
                  }
                >
                  Télécharger le PDF
                </Button>
              );
            }}
          </PDFDownloadLink>
        </Stack>
      </Stack>
      {params.collectionPointIds.length === 1 && (
        <Stack marginTop={2}>
          <Title>{collectionPoint?.name}</Title>
        </Stack>
      )}
      <ReportingCard>
        <ReportingHeader>{`Bilan recyclage du ${formatDate(
          params?.startAt,
          'dd/MM/yyyy',
        )} au ${formatDate(params?.endAt, 'dd/MM/yyyy')}`}</ReportingHeader>

        {isLoading && <Spinner />}

        {isSuccess && reportings.length === 0 && (
          <EmptyState>Rien ici, Veuillez passez votre chemin</EmptyState>
        )}

        {isSuccess && reportings.length !== 0 && (
          <ReportingContent>
            <ReportingGraph data={reportings} />
            <Stack>
              <ReportingItem>
                {reportings.map(({ color, label }, index) => (
                  <Label key={index}>
                    <Circle color={Boolean(color) ? color : theme.primary} />
                    <span>{label}</span>
                  </Label>
                ))}
              </ReportingItem>
              <Values>
                {reportings.map(({ value }, index) => (
                  <TextValue key={index}>
                    {`${formatNumberToPrettyString(value)} kg`}
                  </TextValue>
                ))}
              </Values>
            </Stack>
          </ReportingContent>
        )}
      </ReportingCard>
      {!isEmpty(equivalences) && (
        <Equivalences>
          <EquivalencesTitle>
            Les bénéfices environnementaux et sociaux de votre action
          </EquivalencesTitle>
          <EquivalencesList>
            <Equivalence>
              <img src={icCo2} alt="co2" />
              <div>
                {`${formatNumberToPrettyString(equivalences?.co2)} kg de CO2`}
              </div>
              <div>=</div>
              <IcTrain />
              <div>{`${formatNumberToPrettyString(
                equivalences?.kmTrain,
              )} km en train`}</div>
            </Equivalence>
            <Equivalence>
              <img src={icOil} alt="pétrole" />
              <div>
                {`${formatNumberToPrettyString(
                  equivalences?.oil,
                )} litres de pétrole`}
              </div>
              <div>=</div>
              <IcCar />
              <div>{`${formatNumberToPrettyString(
                equivalences?.kmCar,
              )} km en voiture essence`}</div>
            </Equivalence>
            <Equivalence>
              <img src={icEnergy} alt="énergie" />
              <div>
                {`${formatNumberToPrettyString(
                  equivalences?.energy,
                )} kWh d'énergie`}
              </div>
              <div>=</div>
              <IcComputer />
              <div>{`${formatNumberToPrettyString(
                equivalences?.pc,
              )} h d'utilisation d'un PC`}</div>
            </Equivalence>
            <Equivalence>
              <img src={icWater} alt="eau" />
              <div>
                {`${formatNumberToPrettyString(
                  equivalences?.water,
                )} litres d'eau`}
              </div>
              <div>=</div>
              <IcShower />
              <div>{`${formatNumberToPrettyString(
                equivalences?.shower,
              )} douches de 5 min`}</div>
            </Equivalence>
            <Equivalence>
              <img src={icWork} alt="travail" />
              <div>
                {`${formatNumberToPrettyString(
                  equivalences?.job,
                )} heures de travail`}
              </div>
              <div>=</div>
              <IcPeople />
              <div>{` ${formatNumberToPrettyString(
                equivalences?.etp,
              )} ETP mensuel`}</div>
            </Equivalence>
          </EquivalencesList>
        </Equivalences>
      )}
      {collectionPointsSelectModalIsShown && (
        <Modal
          isOpen
          variant="medium"
          title="Sélectionnez les points de collecte"
          onRequestClose={() => setCollectionPointsSelectModalIsShown(false)}
        >
          <CollectionPointsTree
            collectionPoint={collectionPoint}
            onDismiss={() => setCollectionPointsSelectModalIsShown(false)}
            defaultSelectedSelectionPointIds={params.collectionPointIds}
            onSubmit={handleSelectCollectionPoints}
          />
        </Modal>
      )}
    </Container>
  );
};

export default HomeReporting;
