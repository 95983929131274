import React from 'react';
import { Page, View, Document, Text, Image, Font } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { formatDate } from '@tymate/elise/utils';
import logo from 'images/logo.png';
import { EnvironmentalBenefits, PieChart, DataTable } from 'components/pdf';

const MyPage = styled(Page)`
  display: flex;
  height: 100%;
  width: 100%;
  font-family: 'Helvetica';
  flex-direction: column;
`;

const Main = styled(View)`
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1 1 auto;
  padding: 10px 20px;
`;

const Header = styled(View)`
  width: 100%;
  background-color: #eb732c;
`;

const HeaderText = styled(Text)`
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Helvetica';
  color: #fff;
  width: 100%;
  text-align: center;
`;

const Title = styled(Text)`
  font-size: 16px;
  margin: -1px auto;
  padding-bottom: 8px;
  padding-top: 28px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
`;

const LogoView = styled(View)`
  margin: -1px auto;
  width: 100%;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
`;

const Footer = styled(Header)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const FooterText = styled(HeaderText)``;

const Top = styled(View)`
  padding: 10px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled(View)`
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Value = styled(Text)`
  color: #fff;
  margin-left: 16px;
`;

const Logo = styled(Image)`
  margin: 10px auto;
  width: 75px;
`;

Font.register({
  family: 'Helvetica',
});

Font.register({
  family: 'Helvetica',
});

Font.register({
  family: 'Helvetica',
});

const PdfPage = ({
  reportings,
  reportingsDataTable,
  equivalences,
  canvasUrl,
  params,
}) => {
  return (
    <Document>
      <MyPage>
        <Header>
          <HeaderText>
            bilan recyclage du
            {'\n'}
            {`${formatDate(params?.startAt, 'dd MMMM yyyy')} au ${formatDate(
              params?.endAt,
              'dd MMMM yyyy',
            )}`}
          </HeaderText>
          <Title>{params?.collectionPoint?.name}</Title>
        </Header>
        <Main>
          <Top>
            <PieChart data={reportings} canvasUrl={canvasUrl} />
          </Top>
          <Bottom>
            <EnvironmentalBenefits equivalences={equivalences} />
          </Bottom>
        </Main>
        <Footer>
          <LogoView>
            <Logo src={logo} />
          </LogoView>
          <FooterText>
            {'collecte | Trie | recycle | valorise > pour les pros'}
          </FooterText>
        </Footer>
      </MyPage>
      <MyPage>
        <Header>
          <HeaderText>
            bilan recyclage du{`\n`}
            {`${formatDate(params?.startAt, 'dd MMMM yyyy')} au ${formatDate(
              params?.endAt,
              'dd MMMM yyyy',
            )}`}
          </HeaderText>
        </Header>
        <Main>
          <Top>
            <DataTable
              data={reportingsDataTable}
              startAt={params.startAt}
              endAt={params?.endAt}
            />
          </Top>
          <Bottom>
            <EnvironmentalBenefits equivalences={equivalences} />
          </Bottom>
        </Main>
        <Footer>
          <LogoView>
            <Logo src={logo} />
          </LogoView>
          <FooterText>
            {'collecte | Trie | recycle | valorise > pour les pros'}
          </FooterText>
        </Footer>
      </MyPage>
    </Document>
  );
};

export default PdfPage;
