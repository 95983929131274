import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdClose } from 'react-icons/md';
import { media, ButtonReset } from '@tymate/margaret';

const StyledModal = styled(ReactModal)`
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2147483646;
  padding: 0;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  outline: none;
  border: 0;
  width: 500px;
  max-width: calc(100vw - 32px);

  ${({ size }) =>
    size === 'minHeight' &&
    `
      min-height: 400px
    `}

  ${props =>
    props.variant === 'full' &&
    css`
      width: auto;
    `}

  ${props =>
    props.variant === 'medium' &&
    css`
      width: 800px;
    `}
    
  ${props =>
    props.variant === 'large' &&
    css`
      width: 950px;
    `}

    ${props =>
    props.variant === 'larger' &&
    css`
      width: 1100px;
    `}
`;

ReactModal.setAppElement(document.getElementById('root'));

export const CloseModalTriggerButton = styled(ButtonReset)`
  font-size: 1.5em;
  position: absolute;
  z-index: 2;
  top: ${({ theme }) => theme.spacing()};
  right: ${({ theme }) => theme.spacing()};
  color: #c3b8c6;

  ${props =>
    props.variant === 'fullscreen' &&
    css`
      position: fixed;
      border-radius: 100%
      min-height: 32px;
      min-width: 32px;
      color: #fff;
      display: flex
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.separator};
    `}
`;

const ModalTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  font-size: 24px;
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${props =>
    props.variant === 'fullscreen' &&
    css`
      height: 100%;
      max-height: 100%;
    `}

  ${props =>
    (props.variant === 'full' || props.variant === 'fullscreen') &&
    css`
      padding: 0;
    `}
`;

const overlayStyles = {
  zIndex: 1000,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
};

const ContentModal = ({ variant, title, onRequestClose, children }) => (
  <Content variant={variant}>
    {title && <ModalTitle>{title}</ModalTitle>}

    {onRequestClose && (
      <CloseModalTriggerButton onClick={onRequestClose} variant={variant}>
        <MdClose />
      </CloseModalTriggerButton>
    )}

    {children}
  </Content>
);

const Modal = ({
  title,
  children,
  isOpen,
  onRequestClose,
  overflow,
  variant,
  background,
  isScrollable,
  size,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'auto';
    }

    return () => {
      document.documentElement.style.overflowY = 'auto';
    };
  }, [isOpen]);

  return (
    <StyledModal
      background={background}
      variant={variant}
      overflow={overflow}
      isOpen={isOpen}
      size={size}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          ...overlayStyles,
        },
        content: { overflow },
      }}
    >
      {isScrollable ? (
        <Scrollbars
          autoHeight
          autoHeightMax="calc(100vh - 50px)"
          style={{ flex: 1 }}
        >
          <ContentModal
            variant={variant}
            title={title}
            children={children}
            onRequestClose={onRequestClose}
          />
        </Scrollbars>
      ) : (
        <ContentModal
          variant={variant}
          title={title}
          children={children}
          onRequestClose={onRequestClose}
        />
      )}
    </StyledModal>
  );
};

export default Modal;
