import React, { useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { keys } from 'lodash';
import { useLocation } from 'react-use';
import {
  getColorFromState,
  getLabelColorFromState,
  getNameFromState,
} from '../utils';
import Tooltip from '@tymate/elise/components/Tooltip';
import { Stack } from '@tymate/margaret';
import Truncate from 'react-truncate';

export const VIEWPORT_SIZES = {
  desktop: 1200,
  medium: 1000,
  tablet: 750,
};

const mediaQuery =
  (...query) =>
  (...rules) =>
    css`
      @media ${css(...query)} {
        ${css(...rules)};
      }
    `;

export const media = {
  tablet: mediaQuery`(min-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${VIEWPORT_SIZES.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${VIEWPORT_SIZES.desktop / 16}em)`,
};

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;

const colors = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighten: '#9E9E9E',

  inputSeparator: 'rgba(0, 0, 0, 0.24)',
  separator: 'rgba(0, 0, 0, 0.12)',
  darkSeparator: 'rgba(0, 0, 0, 0.6)',
  disabled: '#eeeeee',
  textDisabled: 'rgba(0, 0, 0, 0.24)',
  textTertiaryDisabled: 'rgba(0, 0, 0, 0.38)',

  background: '#faf9f7',
  blueBackground: '#538FFF',
  greenBackground: '#49DCBB',
  greyBackground: '#E0E0E0',
  orangeLightenBackground: 'rgba(235, 115, 44, 0.08)',

  redTag: '#FADCD9',
  orangeTag: '#FCDDC7',
  yellowTag: '#FAF6CF',
  greenTag: '#D5F0B1',
  tealTag: '#BEEBE7',
  blueTag: '#C9E7F5',
  purpleTag: '#DEE0FA',
  pinkTag: '#F7DAED',
  greyTag: '#DBE3E7',

  redLabel: '#A1160A',
  orangeLabel: '#8F3415',
  yellowLabel: '#090F14',
  greenLabel: '#2E5C0E',
  tealLabel: '#155C5E',
  blueLabel: '#08548A',
  purpleLabel: '#3A45B0',
  pinkLabel: '#961574',
  greyLabel: '#3F5462',

  primary: '#eb732c',
  primaryLight: '#FFA06E',
  primaryLighten: 'rgba(235, 115, 44, 0.12)',
  primaryLighter: 'rgba(224, 53, 27, 0.08)',

  success: '#29CC88',
  error: '#FF3B30',
  errorLight: 'rgba(224, 53, 27, 0.08)',
  danger: '#E0351B',
  warning: '#E29F1B',
  warningLighten: '#FFB74C',
  warningLight: 'rgba(226, 159, 27, 0.08)',
  info: '#7652F3',
  infoLight: 'rgba(118, 82, 243, 0.08)',

  gray: '#767676',
  grayDarker: 'rgba(0, 0, 0, 0.08)',
  grayLight: '#D6D6D6',
  grayLighten: 'rgba(0, 0, 0, 0.04)',
  grayLighter: 'rgba(0, 0, 0, 0.02)',
  white: '#ffffff',
};

export const theme = {
  ...colors,
  spacing,

  borderRadius: '8px',

  mainNav: {
    width: '320px',
    maxWidth: '100%',
    background: {
      mobile: '#ffffff',
      tablet: '#ffffff',
    },
    boxShadow: `0 4px 4px rgba(0,0,0,0.05)`,
    position: 'top',
    height: '80px',
    triggerColor: {
      expanded: colors.text,
      collapsed: colors.text,
    },
  },

  button: {
    transition: 'background 150ms ease, color 150ms ease',
    borderRadius: '999em',
    alignY: 'center',
    alignX: 'center',
    fontWeight: 500,
    paddingHorizontal: '1rem',
    paddingVertical: '0.625rem',
    minWidth: 0,
    color: colors.primary,
    colorDisabled: colors.textDisabled,
    colorHover: colors.greenDark,
    colorFocus: colors.greenDark,
    backgroundHover: colors.primaryLighten,

    primary: {
      background: colors.primary,
      color: '#fff',
      backgroundHover: colors.primaryLight,
      backgroundDisabled: colors.disabled,
      colorDisabled: colors.textDisabled,
      boxShadowDisabled: 'none',
    },

    outline: {
      background: 'transparent',
      color: colors.primary,
      backgroundHover: colors.primaryLighten,
      backgroundDisabled: 'transparent',
      boxShadow: 'inset 0 0 0 1px currentColor',
      colorDisabled: colors.textDisabled,
    },

    white: {
      background: colors.white,
      paddingHorizontal: '12px',
      paddingVertical: '6px',
      color: colors.primary,
      backgroundHover: colors.white,
      backgroundDisabled: 'transparent',
      boxShadow: 'none',
      colorDisabled: colors.textDisabled,
    },
  },
};

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing : antialiased;
    color: ${({ theme }) => theme.text};
  }

  a {
    color: ${({ theme }) => theme.primary};
  }

  input {
    font-size: inherit;
  }
`;

export const ButtonReset = styled.button`
  appearance: none;
  outline: none;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;
  font-size: inherit;
  transition: opacity 100ms ease;
  line-height: inherit;
  font-family: inherit;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const RawLink = styled(Link)`
  *: inherit;
`;

export const Button = styled(ButtonReset)`
  display: flex;
  border-radius: 28px;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.5)};
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  min-width: 10em;
  min-height: 48px;
  transition: background-color 100ms ease;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
    `}

  ${({ size, theme }) =>
    size === 'big' &&
    css`
      padding: ${theme.spacing()} ${theme.spacing(1.5)};
    `}
  
  ${({ size, theme }) =>
    size === 'small' &&
    css`
      padding: ${theme.spacing(0.5)} ${theme.spacing()};
    `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.primary};
      color: #fff;

      &:hover {
        background-color: ${theme.primaryLight};
      }

      &:disabled {
        background-color: ${theme.disabled};
        color: ${theme.textDisabled};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'outline' &&
    css`
      background-color: transparent;
      color: ${theme.primary};
      border: 1px solid ${theme.primary};

      &:hover {
        background-color: ${theme.primaryLighten};
      }

      &:disabled {
        border: 1px solid ${theme.separator};
        color: ${theme.textDisabled};
        &:hover {
          background-color: transparent;
        }
      }
    `}

    ${({ size }) =>
    size === 'small' &&
    css`
      min-height: 0;
      font-weight: 500;
      font-size: 14px;
      padding: ${theme.spacing(0.25)} ${theme.spacing(0.5)};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: #fff;
    `}

    ${({ hasMargin }) =>
    hasMargin &&
    css`
      margin: ${theme.spacing(4)};
    `}

  
  ${({ variant, theme }) =>
    variant === 'phantom' &&
    css`
      background-color: transparent;
      color: ${theme.primary};

      &:hover {
        background-color: ${theme.primaryLighten};
      }

      &:disabled {
        color: ${theme.textDisabled};
        &:hover {
          background-color: transparent;
        }
      }
    `}

    ${({ variant, theme }) =>
    variant === 'underlined' &&
    css`
      padding: 0px;
      font-size: 14px;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      color: ${theme.primary};

      &:hover {
        background-color: ${theme.primaryLighten};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'input' &&
    css`
      background-color: ${theme.primary};
      color: #fff;
      border-radius: 0 4px 4px 0;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      padding: ${theme.spacing(0.5)} ${theme.spacing()};
      min-width: auto;

      &:hover {
        background-color: ${theme.primaryLight};
      }

      &:disabled {
        color: ${theme.textDisabled};
        &:hover {
          background-color: transparent;
        }
      }
    `}

  > * {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  > * {
    margin-left: ${({ theme }) => theme.spacing()};
  }

  ${({ hasMarginTop, theme }) =>
    hasMarginTop &&
    css`
      padding-top: ${theme.spacing(2)};
    `}

  ${({ alignment }) =>
    alignment === 'right' &&
    css`
      justify-content: flex-end;
    `}

  ${({ alignment }) =>
    alignment === 'center' &&
    css`
      justify-content: center;
    `}

  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
    `}
`;

export const Container = styled.div`
  width: 1200px;
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  margin-left: auto;
  margin-right: auto;

  ${({ variant, theme }) =>
    variant === 'main' &&
    media.tablet`
      padding: ${theme.spacing(2)};
    `}

  ${({ variant, theme }) =>
    variant === 'thin' &&
    media.tablet`
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    `}

  ${({ variant, theme }) =>
    variant === 'breadcrumb' &&
    css`
      padding: ${theme.spacing(2)} ${theme.spacing()} 0;
    `}

  ${({ variant, theme }) =>
    variant === 'subHeader' &&
    css`
      padding: ${theme.spacing(2)} ${theme.spacing(2)} 0;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 95%;
      min-width: 1200px;
    `}

  ${({ size }) =>
    size === 'narrow' &&
    css`
      width: 800px;
    `}

  ${({ size }) =>
    size === 'tight' &&
    css`
      width: 40rem;
    `}
`;

export const Section = styled.div`
  width: 100%;

  > *:first-child {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }

  + * {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const TextLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  font-weight: 400;
  outline: none;
  border: 0;
  line-height: 1;
  transition: color 150ms ease;
  font-size: 0.75rem;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }

  ${({ underlined }) =>
    underlined &&
    `
      text-decoration: underline;
    `}

  ${({ size }) =>
    size === 'big' &&
    `
      font-size: 1.125rem;
    `}
`;

export const MediaImage = styled.div`
  display: flex;
`;

export const MediaBody = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing()};

  ${props =>
    props.variant === 'compact' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(0.5)};
    `};
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;

  ${props =>
    (props.variant === 'center' || props.verticalAlign === 'center') &&
    css`
      align-items: center;
    `};

  ${props =>
    props.isMargedBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.size === 'tight' &&
    css`
      ${MediaBody} {
        padding-left: ${({ theme }) => theme.spacing(0.5)};
      }
    `};

  ${props =>
    props.variant === 'inverted' &&
    css`
      ${MediaBody} {
        padding-left: 0;
      }
    `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-1)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  justify-content: right;

  ${({ verticalAlign }) =>
    verticalAlign === 'center' &&
    `
      align-items: center;
    `}

  ${({ verticalAlign }) =>
    verticalAlign === 'bottom' &&
    `
      align-items: flex-end;
    `}

    ${({ horizontalAlign }) =>
    horizontalAlign === 'left' &&
    `
    justify-content: left;
    `}

  ${media.tablet`
    width: calc(100% + ${({ theme }) => theme.spacing()});
  `}

  ${({ gutterVariant, theme }) =>
    gutterVariant === 'medium' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-1.5)};
      margin-left: ${({ theme }) => theme.spacing(-1.5)};
      width: calc(100% + ${({ theme }) => theme.spacing(1.5)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(1.5)}!important;
        padding-left: ${({ theme }) => theme.spacing(1.5)}!important;
      }
    `};

  ${({ gutterVariant, theme }) =>
    gutterVariant === 'large' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-2)};
      margin-left: ${({ theme }) => theme.spacing(-2)};
      width: calc(100% + ${({ theme }) => theme.spacing(2)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(2)}!important;
        padding-left: ${({ theme }) => theme.spacing(2)}!important;
      }
    `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `};
`;

export const Cell = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${
              Math.floor(props.sizes[breakpoint] * 10000) / 100
            }%`};
          `,
        )};
    `};

  ${props =>
    props.align === 'right' &&
    css`
      text-align: right;
    `}

  ${props =>
    props.min &&
    css`
      flex: 0;
      white-space: nowrap;
    `};

  ${props =>
    props.hiddenUntilTablet &&
    css`
      display: none;

      ${media.tablet`
        display: block;
      `}
    `}

  ${({ hasNoPaddingY }) =>
    hasNoPaddingY &&
    `
      padding-top: 0;
      padding-bottom: 0;
    `}


  ${({ hasNoPaddingX }) =>
    hasNoPaddingX &&
    `
      padding-left: 0;
      padding-right: 0;
    `}

    ${({ hasNoPadding }) =>
    hasNoPadding &&
    `
      padding-top: 0;
      padding-left: 0;
    `}

    ${({ flexValue }) =>
    Boolean(flexValue) &&
    css`
      flex: ${flexValue};
    `}

    ${({ alignRight }) =>
    Boolean(alignRight) &&
    css`
      display: flex;
      justify-content: flex-end;
      width: 100%;
    `}

    ${({ alignLeft }) =>
    Boolean(alignLeft) &&
    css`
      display: flex;
      justify-content: flex-start;
      width: 100%;
    `}

    ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop}rem;
    `}
`;

export const Icon = styled(Stack)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;

  ${({ hasNoMargin }) =>
    hasNoMargin &&
    `
      margin-right: 0;
    `}

  ${({ variant }) =>
    variant === 'isolated' &&
    `
      display: flex;
    `}
`;

export const ActionButton = styled(ButtonReset)`
  display: flex;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.textLighten};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  + * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.disabled};
      cursor: not-allowed;

      &:hover {
        color: ${({ theme }) => theme.disabled};
      }
    `}
`;

export const IconAndText = styled.span`
  display: flex;
  align-items: center;

  > ${Icon} {
    display: flex;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: left;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.text};
  font-size: 1.25rem;
  line-height: 1.2;
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
  white-space: nowrap;

  ${({ noTopMargin, theme }) =>
    noTopMargin &&
    css`
      margin-top: ${theme.spacing()};
    `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
`;

export const TitleAndAction = styled(Stack).attrs(props => ({
  alignX: 'space-between',
  alignY: props?.alignY || 'center',
}))`
  ${Title} {
    margin-bottom: 0;
  }

  ${({ hasTopMargin, theme }) =>
    hasTopMargin &&
    css`
      margin-top: ${theme.spacing()};
    `}

  ${({ hasBottomMargin, theme }) =>
    hasBottomMargin &&
    css`
      margin-bottom: ${theme.spacing()};
    `}

  ${({ hasBottomBorder, theme }) =>
    hasBottomBorder &&
    css`
      padding-bottom: ${theme.spacing()};
      border-bottom: 1px solid ${theme.separator};
    `}
`;

export const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing(2)};
  position: relative;
  width: 100%;

  ${({ variant }) =>
    variant === 'modal' &&
    css`
      padding: ${({ theme }) => theme.spacing(0)};
      border-radius: none;
      border: none;
    `}
`;

export const IconButton = styled.div`
  background-color: transparent;
  color: ${theme.primary};
  border: 1px solid ${theme.primary};
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;

  &:hover {
    background-color: ${theme.primaryLighten};
  }

  &:disabled {
    border: 1px solid ${theme.separator};
    color: ${theme.textDisabled};
    &:hover {
      background-color: transparent;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(-1)};

  > * {
    margin-left: ${({ theme }) => theme.spacing()};
  }

  ${({ verticalAlign }) =>
    verticalAlign === 'center' &&
    `
    align-items: center;
  `}

  ${({ verticalAlign }) =>
    verticalAlign === 'bottom' &&
    `
      align-items: flex-end;
    `}

  ${({ horizontalAlign }) =>
    horizontalAlign === 'spaced' &&
    `
    justify-content: space-between;
  `}
`;

const generateToPropWithSearchIncluded = ({ search, to }) => {
  if (typeof to === 'object') {
    return {
      ...to,
      search,
    };
  }

  return {
    pathname: to,
    search,
  };
};

export const QueryParamsAwareNavLink = ({ to, ...props }) => {
  const { search } = useLocation();
  let linkTo = generateToPropWithSearchIncluded({ to, search });

  return <NavLink to={linkTo} {...props} />;
};

export const FreqTag = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  color: ${({ theme }) => theme.textLight};
  border-radius: 4px;
  font-size: 0.875rem;
  background-color: ${({ theme }) => theme.grayLighten};
  width: 32px;
  height: 32px;
`;

export const TagWrapper = styled.div`
  display: inline;
  color: #fff;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ color, theme }) =>
    color &&
    `
        background-color: ${theme[color]};
      `}
  ${({ fontColor, theme }) =>
    fontColor &&
    `
        color: ${theme[fontColor]};
      `}
`;

export const Tag = ({ status, widthMax = 70 }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const nameStatus = getNameFromState(status);
  const colorStatus = getColorFromState(status);
  const labelColorStatus = getLabelColorFromState(status);

  return Boolean(nameStatus) ? (
    <Tooltip disabled={!isTruncated} variant="medium" tip={nameStatus}>
      <TagWrapper color={colorStatus} fontColor={labelColorStatus}>
        <Truncate
          onTruncate={value => setIsTruncated(value)}
          lines={1}
          trimWhitespace
          ellipsis={<span>...</span>}
          width={widthMax}
        >
          {nameStatus}
        </Truncate>
      </TagWrapper>
    </Tooltip>
  ) : null;
};

export const PrimaryLink = styled(RawLink)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    padding-left: ${({ theme }) => theme.spacing(0.25)};
  }

  + * {
    padding-left: ${({ theme }) => theme.spacing()};
  }
`;

export const Legend = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.textLight};
`;

export const LegendLighten = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.textLighten};
`;

export const SingleLine = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
`;

export const EmptyState = styled(Stack)`
  width: 100%;
  align-items: column;
  justify-content: center;
  color: ${({ theme }) => theme.textLighten};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(3)};
  `}
`;

export const FunctionTag = styled.div`
  box-sizing: border-box;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 22px;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  font-size: 0.875rem;
`;
export const HabilitationTag = styled.div`
  box-sizing: border-box;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 22px;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  font-size: 0.875rem;
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.primaryLighten};
  color: ${({ theme }) => theme.primary};
  padding: 0px 5px;
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  max-width: fit-content;
  margin-left: auto;
  height: 24px;
  font-size: 0.75rem;
`;
