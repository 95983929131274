import styled, { css } from 'styled-components';
import { ButtonReset } from './';

export const PopoverContainer = styled.div`
  position: relative;
`;

export const PopoverMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  min-width: 100%;
  width: 200px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: auto;

  ${({ size }) =>
    size === 'auto' &&
    css`
      width: auto;
    `}

  ${({ size }) =>
    size === 'maxContent' &&
    css`
      width: max-content;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      width: 280px;
    `}

  ${({ isACompactDropList }) =>
    isACompactDropList &&
    css`
      max-height: 205px;
    `};

  ${({ alignment }) =>
    alignment === 'right' &&
    css`
      left: auto;
      right: 0;
    `};

  ${({ top }) =>
    top &&
    css`
      bottom: calc(100% + ${({ theme }) => theme.spacing(3)});
      top: auto;
    `};

  ${({ variant, theme }) =>
    variant === 'tooltip' &&
    css`
      padding: ${theme.spacing()};
      max-height: 300px;
      width: 300px;
      top: auto;
      bottom: calc(100% + ${theme.spacing(1.5)});
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    `}

  ${({ variant, theme }) =>
    variant === 'tooltipBottom' &&
    css`
      padding: ${theme.spacing()};
      max-height: 300px;
      width: 300px;
      top: auto;
      transform: translateX(-30%);
    `}


  ${({ variant, theme }) =>
    variant === 'modal' &&
    css`
      padding: ${theme.spacing()};
      max-height: 300px;
      width: 300px;
      top: auto;
      left: 40%;
      right: auto;
      transform: translateX(-50%);
    `}
`;

export const PopoverItemButton = styled(ButtonReset).attrs({ type: 'button' })`
  display: flex;
  text-align: center;
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  cursor: pointer;
  text-align: left;
  line-height: 1.1;
  font-size: 1rem;
  color: ${({ theme }) => theme.textLight};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  ${({ actionButton }) =>
    actionButton &&
    css`
      margin: ${({ theme }) => theme.spacing(0.25)};
      border-radius: 4px;

      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${({ theme }) => theme.grayLighten};
          color: ${({ theme }) => theme.textTertiaryDisabled};
        `}

      &:hover {
        background-color: ${({ theme }) => theme.primaryLighten};
      }
    `}

  ${props =>
    props.isActive &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

  ${({ hasBottomBorder }) =>
    hasBottomBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.separator};
    `}

    ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.danger};
    `}

    margin: 0 ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    background: ${({ theme }) => theme.primaryLighter};
  }

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      color: ${({ theme }) => theme.danger};
      border-radius: 4px;

      &:hover {
        color: ${({ theme }) => theme.danger};
        background: ${({ theme }) => theme.errorLight};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.textDisabled};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const PopoverItem = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
  &:last-child {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const TitleDropdown = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-weight: 700;
  color: ${({ theme }) => theme.textLight};
`;
